@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .primary-btn {
    @apply inline-block bg-primary text-white font-semibold rounded-lg hover:bg-secondary duration-200 shadow-[0px_10px_8px_-7px_#ffac1d] hover:shadow-[0px_10px_8px_-7px_#69a79c] py-2 px-6;
  }
  .secondary-btn {
    @apply inline-block bg-secondary text-white font-semibold rounded-lg hover:bg-primary duration-200 shadow-[0px_10px_8px_-7px_#6a56f6] hover:shadow-[0px_10px_8px_-7px_#69a79c] py-2 px-6;
  }
}

@layer utilities {
  .drop-shadow {
    filter: drop-shadow(6px 8px 10px rgba(0, 0, 0, 0.5));
  }
}
